<template>
  <div class="admin-users">
    <portal to="page-call-to-action">
      <ebp-button @click="showAddUser = true">{{
        $t("add-client")
      }}</ebp-button>
    </portal>
    <advanced-data-table
      action="users/get"
      :headers="headers"
      ref="table"
      :limit="50"
      :filters.sync="filters"
    >
      <template #cell(created_at)="{item}">{{
        formatDate(item.created_at, "dd/MM/yyyy")
      }}</template>
      <template #cell(name)="{item}">
        {{ item.name }}
      </template>

      <template #cell(is_outstanding)="{item}">
        <i
          v-if="item.is_outstanding"
          class="bg-danger text-white mdi mdi-exclamation-thick"
          style="border-radius: 100%; padding: 0 1.5px; font-size: .8rem"
          v-tooltip="$t('outstanding')"
        ></i>
        <span v-else>-</span>
      </template>

      <template #cell(role)="{item}">{{ $t(item.role) }} </template>

      <template #cell(last_seen)="{item}">{{
        item.last_seen ? fromNow(item.last_seen) : $t("hasnt-logged-in")
      }}</template>
      <!-- Actions -->
      <template #actions="{item}">
        <b-button
          variant="primary"
          size="sm"
          @click="edit(item)"
          v-tooltip.top-center="$t('edit')"
        >
          <i class="bx bx-pencil"></i>
          <span v-if="!isSmallScreen"> </span>
        </b-button>
        <b-button
          variant="warning"
          size="sm"
          class="ml-1"
          @click="proxy(item)"
          v-if="item.role === 'client'"
          v-tooltip.top-center="$t('proxy')"
        >
          <i class="bx bxs-network-chart"></i>
          <span v-if="!isSmallScreen"> </span>
        </b-button>
      </template>

      <!-- Status -->
      <template #cell(status)="{ item }">
        <!-- Use statusVariant from @/mixins/colors.js -->
        <h5 class="m-0">
          <b-badge
            :variant="statusVariant(item.is_active ? 'active' : 'inactive')"
            class="mr-1"
            >{{ $t(item.is_active ? "active" : "inactive") }}</b-badge
          >
        </h5>
      </template>

      <!-- Filters -->
      <template #filters>
        <!-- Status filter -->
        <adt-filter :label="$t('status')">
          <b-form-select
            :options="addAllOption(states)"
            v-model="filters.is_active"
            size="sm"
          ></b-form-select>
        </adt-filter>

        <!-- Status filter -->
        <adt-filter :label="$t('role')">
          <b-form-select
            :options="addAllOption(roles)"
            v-model="filters.role"
            size="sm"
          ></b-form-select>
        </adt-filter>

        <adt-filter :label="$t('outstanding')">
          <b-form-select
            :options="addAllOption(oustandingValues)"
            v-model="filters.is_outstanding"
            size="sm"
          ></b-form-select>
        </adt-filter>
      </template>
    </advanced-data-table>

    <!-- User edit modal -->
    <ebp-modal :open.sync="open" :tabs="editTabs">
      <template #error><error :err.sync="error"/></template>

      <!-- Edit details -->
      <template #edit-details>
        <form-generator
          v-if="editedItem"
          :elements="elements"
          :data="editedItem"
          :handleUpdate="({ key }, v) => (editedItem[key] = v)"
          @click.stop="$event.target.type === 'button' ? save : ''"
        />
      </template>

      <!-- Change password -->
      <template #change-password>
        <form-generator
          v-if="editedItem"
          :elements="changePasswordElements"
          :data="passwords"
          :handleUpdate="({ key }, v) => (passwords[key] = v)"
          @click.stop="$event.target.type === 'button' ? save : ''"
        />
      </template>
    </ebp-modal>

    <!-- User add modal -->
    <ebp-modal :open.sync="showAddUser" :tabs="addTabs">
      <template #error><error :err.sync="error"/></template>

      <!-- Edit details -->
      <template #add-client>
        <p>
          Enter details for the user. Fields marked with an asterisk (*) are
          required.
        </p>
        <AddUser @added="added" />
      </template>
    </ebp-modal>
  </div>
</template>

<script>
import AdvancedDataTable from "@/components/advanced-data-table";
import FormGenerator from "../../../components/form-generator.vue";
import userSchema from "@/validation-schemas/user";
import AddUser from "@/views/admin/users/Add";
import UserRoles from "@/fixtures/roles";
import AdtFilter from "@/components/core/adt-filter";
import roles from "@/fixtures/roles";

export default {
  name: "admin-users",
  components: {
    AdvancedDataTable,
    FormGenerator,
    AddUser,
    AdtFilter
  },
  data() {
    return {
      headers: [
        { key: "created_at", label: "registered_at", sortable: true },
        "name",
        "role",
        "email",
        "last_seen",
        "status",
        { key: "is_outstanding", label: "os-acc" }
      ],
      editedItem: null,
      saving: false,
      open: false,
      showAddUser: false,
      error: null,
      editTabs: ["edit-details", "change-password"],
      addTabs: ["add-client"],
      passwords: {
        new_password: "",
        confirm_password: ""
      },
      filters: {
        is_active: null,
        role: null,
        is_outstanding: null
      },
      states: ["active", "inactive"].map(i => ({
        text: this.$t(i),
        value: i === "active" ? 1 : 0
      })),
      roles: UserRoles.map(r => ({
        text: this.$t(r),
        value: r
      })),
      oustandingValues: ["yes", "no"].map(i => ({
        text: this.$t(i),
        value: i === "yes" ? 1 : 0
      }))
    };
  },
  computed: {
    user() {
      return this.$store.getters["auth/user"];
    },
    elements() {
      return [
        {
          key: "name",
          label: "name",
          type: "string"
        },
        {
          key: "email",
          label: "email",
          type: "string"
        },
        {
          key: "role",
          label: "role",
          type: "select",
          attrs: {
            options: roles.map(s => ({
              text: this.$t(s),
              value: s
            }))
          }
        },
        {
          key: "is_active",
          label: "active",
          type: "switch"
        },
        {
          key: "is_outstanding",
          label: "outstanding",
          type: "switch"
        },
        {
          key: "is_enrolled_in_self_billing",
          label: "enrolled-in-self-billing",
          type: "switch"
        },
        {
          type: "button-group",
          className: "float-right",
          elements: [
            {
              text: "delete",
              type: "button",
              className: "px-5 float-right mt-3 btn-danger",
              attrs: { variant: "danger" },
              events: {
                click: this.remove
              }
            },
            {
              text: "save",
              type: "button",
              className: "px-5 float-right mt-3",
              events: {
                click: this.save
              }
            }
          ]
        }
      ];
    },
    changePasswordElements() {
      return [
        {
          key: "new_password",
          label: "new-password",
          type: "password"
        },
        {
          key: "confirm_password",
          label: "confirm-password",
          type: "password"
        },
        {
          text: "change",
          type: "button",
          className: "px-5 float-right mt-3",
          events: {
            click: this.changePassword
          }
        }
      ];
    }
  },
  methods: {
    async proxy(user) {
      if (confirm(this.$t("proxy-user", { name: user.name }))) {
        this.ebpLoader(true);

        try {
          const res = await this.$store.dispatch("proxy/proxy", user.id);
          const oldCredentials = {
            user: this.$store.getters["auth/user"],
            token: this.$store.getters["auth/token"]
          };
          this.$store.commit("auth/setUserData", res.data);
          this.$store.commit("proxy/setUserData", oldCredentials);
          const { href } = this.$router.resolve({
            name: "ClientDashboard"
          });
          location = href;
        } catch (err) {
          console.error(err);
        }

        this.ebpLoader(false);
      }
    },
    added() {
      this.showAddUser = false;
      this.$refs.table.get();
    },
    async remove() {
      const user = this.editedItem;
      if (confirm(this.$t("remove-user", { name: user.name }))) {
        try {
          await this.$store.dispatch("users/delete", user.id);
          this.$refs.table.change("id", user.id);
          this.open = false;
          this.editedItem = null;
        } catch (err) {
          console.error(err);
        }
      }
    },
    edit(user) {
      this.editedItem = user;
      this.open = true;
    },
    async save() {
      this.error = null;
      const user = this.editedItem;
      const validation = userSchema.validate(user, this.joiOptions);

      if (validation.error) {
        this.error = validation.error.message;
      } else {
        this.saving = true;
        try {
          await this.$store.dispatch("users/update", user);
          this.$refs.table.change("id", user.id, user);
          this.open = false;
          this.editedItem = null;
        } catch (err) {
          this.error = this.errorRes(err);
        }
        this.saving = false;
      }
    },
    async changePassword() {
      this.error = null;
      const { new_password, confirm_password } = this.passwords;

      if (!new_password || !confirm_password) {
        this.error = "validation-errors.required-all";
      } else if (new_password !== confirm_password) {
        this.error = "validation-errors.passwords-dont-match";
      } else {
        try {
          await this.$store.dispatch("users/changePassword", {
            id: this.editedItem.id,
            new_password
          });
        } catch (err) {
          this.error = this.errorRes(err);
        }
      }
    }
  }
};
</script>

<style></style>
